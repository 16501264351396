import { Login } from "@mui/icons-material";
import { Button, Input, Sheet } from "@mui/joy";
import { Controller, useForm } from "react-hook-form";
import { LoginRequest, useLogin } from "../../hooks/auth";

interface Props {
  setShowLoginModal?: (value: boolean) => void;
}

const LogInForm = (props: Props) => {
  const { setShowLoginModal } = props;
  const { control, handleSubmit } = useForm<LoginRequest>({
    defaultValues: { email: "", password: "" },
  });
  const { isLoggingIn, login } = useLogin(setShowLoginModal);

  return (
    <form onSubmit={handleSubmit(login)}>
      <Sheet
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          padding: 3,
          minWidth: 300,
        }}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="email address"
              type="email"
              autoComplete="email"
              autoFocus
              fullWidth
              variant="outlined"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="password"
              type="password"
              autoComplete="password"
              autoFocus
              fullWidth
              variant="outlined"
            />
          )}
        />
        <Button type="submit" endDecorator={<Login />} loading={isLoggingIn}>
          Sign in
        </Button>
      </Sheet>
    </form>
  );
};

export default LogInForm;
