import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhone";
import { Box, Stack, Typography } from "@mui/joy";
export default function ContactInfo({
  full_name,
  email,
  phone_number,
}: {
  full_name: string;
  email: string;
  phone_number: string;
}) {
  return (
    <Box
      borderTop="1px solid rgb(205, 215, 225)"
      borderBottom="1px solid rgb(205, 215, 225)"
      paddingY={1}
      marginX={4}
    >
      <Stack gap={2} direction="row" alignItems="center">
        <ContactPhoneOutlinedIcon sx={{ width: 36 }} />
        <Box>
          <Typography fontWeight="lg">{full_name}</Typography>
          <Typography level="body-sm" fontStyle="italic">
            {email}
          </Typography>
          <Typography level="body-sm">{phone_number}</Typography>
        </Box>
      </Stack>
    </Box>
  );
}
