import { List, ListItem } from "@mui/joy";
import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import { Fragment } from "react";
import { Return } from "../../pages/entities/types";
import { fetchWithAuth } from "../../utils/fetching";

export const Route = createFileRoute("/_protected/returns")({
  component: ReturnsPage,
  loader: async () => fetchWithAuth("/be-api/internal/returns/"),
});

function ReturnsPage() {
  const { results: returns } = useLoaderData({
    from: "/_protected/returns",
  }) as {
    results: Return[];
  };
  return (
    <Fragment>
      <h2>Returns</h2>
      <p>The most recent returns.</p>
      <List>
        {returns.map((r) => (
          <ListItem key={r.id}>
            <a href={`/return/${r.id}`}>{r.id}</a>
          </ListItem>
        ))}
      </List>
    </Fragment>
  );
}
