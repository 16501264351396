import { Box, Typography } from "@mui/joy";
import {
  ErrorComponentProps,
  Outlet,
  createRootRoute,
  useNavigate,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { useHotkeys } from "react-hotkeys-hook";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

export const Route = createRootRoute({
  component: RootComponent,
  errorComponent,
  notFoundComponent,
});

function RootComponent() {
  const navigate = useNavigate();
  useHotkeys("ctrl+k,meta+k", () => navigate({ to: "/omnisearch" }));

  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <Sidebar />
      <Header />
      <Box
        component="main"
        sx={{
          m: 5,
          width: "100%",
        }}
      >
        <Outlet />
        <TanStackRouterDevtools position="bottom-right" />
      </Box>
    </Box>
  );
}

function errorComponent({ error, info }: ErrorComponentProps) {
  return (
    <Box>
      <h2>Error:</h2>
      <p>{(error as Error).message}</p>
      <p>{info?.componentStack}</p>
    </Box>
  );
}

function notFoundComponent() {
  return (
    <Box>
      <Typography>Sorry, this page doesn't exist!</Typography>
    </Box>
  );
}
