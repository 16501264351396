import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import ContactInfo from "../../../components/ContactInfo";
import IdDisplay from "../../../components/IdDisplay";
import { Return } from "../../../pages/entities/types";
import { fetchWithAuth } from "../../../utils/fetching";

export const Route = createFileRoute("/_protected/return/$returnId")({
  component: ReturnDetailsPage,
  loader: async ({ params }) =>
    fetchWithAuth(`/be-api/internal/returns/${params.returnId}/`),
});

function ReturnDetailsPage() {
  const returnObj = useLoaderData({
    from: "/_protected/return/$returnId",
  }) as Return;

  const historyData = [
    {
      logo: "https://via.placeholder.com/24",
      role: "PENDING -> COMPLETED",
      name: "Admin User",
      years: returnObj.created_at,
    },
    {
      logo: "https://via.placeholder.com/24",
      role: "COMPLETED -> CANCELED",
      name: "Fake User",
      years: returnObj.modified_at,
    },
  ];

  return (
    <Sheet
      variant="outlined"
      sx={{
        borderRadius: "sm",
        p: 4,
        fontSize: "0.8rem",
      }}
    >
      <Grid container spacing={6}>
        <Grid xs={4}>
          <Stack>
            <Stack direction="row" spacing={1}>
              <h2>Return</h2>
              <Chip variant="solid" color="primary">
                {returnObj.status}
              </Chip>
            </Stack>
            <IdDisplay id={returnObj.id} />
            <h3>Partner</h3>
            <Typography level="body-sm" fontWeight={600}>
              {returnObj.partner.name}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <h3>Recovery method</h3>
            <Chip variant="outlined" color="success">
              {returnObj.recovery_method.name}
            </Chip>
            <Divider sx={{ my: 2 }} />
            <h3>Returned by</h3>
            <ContactInfo {...returnObj.returned_by} />
            <Divider sx={{ my: 2 }} />
            <Card>
              <CardContent>
                <Typography level="title-sm">Created</Typography>
                <Typography level="body-sm">{returnObj.created_at}</Typography>
                <Divider sx={{ my: 1 }} />
                <Typography level="title-sm">Modified</Typography>
                <Typography level="body-sm">{returnObj.modified_at}</Typography>
                <Divider sx={{ my: 1 }} />
                <Typography level="title-sm">Returned</Typography>
                <Typography level="body-sm">{returnObj.returned_at}</Typography>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: "flex", gap: 2 }}>
                  <ButtonGroup
                    aria-label="radius button group"
                    size="sm"
                    variant="outlined"
                    sx={{
                      "--ButtonGroup-radius": "10px",
                      "--Button-minHeight": "1.5rem",
                    }}
                  >
                    <Button variant="solid">UTC</Button>
                    <Button>PST</Button>
                  </ButtonGroup>
                  <ButtonGroup
                    aria-label="radius button group"
                    size="sm"
                    variant="outlined"
                    sx={{
                      "--ButtonGroup-radius": "10px",
                      "--Button-minHeight": "1.5rem",
                    }}
                  >
                    <Button variant="solid">ISO</Button>
                    <Button>Human</Button>
                  </ButtonGroup>
                </Box>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
        <Divider orientation="vertical" />
        <Grid xs={7}>
          <Stack>
            <h3>Associated objects</h3>
            <Stack sx={{ marginLeft: 4 }}>
              <Stack direction="row" spacing={1}>
                <ChecklistRoundedIcon sx={{ width: 36 }} />
                <Typography color="success" variant="soft">
                  Match
                </Typography>
                <Chip variant="outlined" size="sm" color="primary">
                  {returnObj.match.status}
                </Chip>
              </Stack>
              <IdDisplay id={returnObj.match.id} />
            </Stack>
            <Divider component="div" sx={{ my: 2 }} />
            <Stack sx={{ marginLeft: 8 }}>
              <Stack direction="row" spacing={1}>
                <PhoneIphoneIcon sx={{ width: 36 }} />
                <Typography color="success" variant="soft">
                  Item
                </Typography>
                <Chip variant="outlined" size="sm" color="primary">
                  {returnObj.match.status}
                </Chip>
              </Stack>
              <IdDisplay id={returnObj.match.item.id} />
            </Stack>
            <Divider component="div" sx={{ my: 2 }} />
            <Stack sx={{ marginLeft: 8 }}>
              <Stack direction="row" spacing={1}>
                <ContentPasteSearchIcon sx={{ width: 36 }} />
                <Typography color="success" variant="soft">
                  Claim
                </Typography>
                <Chip variant="outlined" size="sm" color="primary">
                  {returnObj.match.status}
                </Chip>
              </Stack>
              <IdDisplay id={returnObj.match.claim.id} />
              <h3>Submitted by</h3>
              <ContactInfo {...returnObj.match.claim.user} />
            </Stack>
            <Divider component="div" sx={{ my: 2 }} />
            <h3>
              History{" "}
              <span style={{ fontWeight: "normal", fontStyle: "italic" }}>
                (Not real)
              </span>
            </h3>
            <List sx={{ "--ListItemDecorator-size": "40px", gap: 2 }}>
              {historyData.map((company, companyIndex) => (
                <ListItem key={companyIndex} sx={{ alignItems: "flex-start" }}>
                  <ListItemDecorator
                    sx={{
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        height: "100%",
                        width: "1px",
                        bgcolor: "divider",
                        left: "calc(var(--ListItem-paddingLeft) + 12px)",
                        top: "50%",
                      },
                    }}
                  >
                    <Avatar
                      src={company.logo}
                      sx={{ "--Avatar-size": "24px" }}
                    />
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography level="title-sm">{company.role}</Typography>
                    <Typography level="body-xs">{company.name}</Typography>
                  </ListItemContent>
                  <Typography level="body-xs">
                    {company.years.toString()}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <Button
              size="sm"
              variant="plain"
              endDecorator={<KeyboardArrowRightRoundedIcon fontSize="small" />}
              sx={{ px: 1, mt: 1 }}
            >
              Expand
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Divider component="div" sx={{ my: 4 }} />
      <Accordion sx={{ display: "flex", gap: 1 }}>
        <AccordionSummary>JSON Data</AccordionSummary>
        <AccordionDetails>
          <JsonView src={returnObj} />
        </AccordionDetails>
      </Accordion>
    </Sheet>
  );
}
