import axios from "axios";

// Currently not being used
export async function fetchData<T>(url: string) {
  const response = await axios.get(url);
  return response.data as T;
}

// Currently only used for login (/be-api/partner/token/)
export async function postData<T>(url: string, data: object, options = {}) {
  const response = await axios.post(url, data, options);
  return response.data as T;
}

export async function fetchWithAuth<T>(
  url: string,
  method = "GET",
): Promise<T> {
  const api = axios.create();

  api.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        config.headers.Authorization = `JWT ${accessToken}`;
        return config;
      } else {
        return Promise.reject("No access token");
      }
    },
    (error) => Promise.reject(error),
  );

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        axios
          .post("/be-api/auth/token-refresh/", {
            refresh: localStorage.getItem("refreshToken"),
          })
          .then((response) => {
            const { access: accessToken } = response.data;

            localStorage.setItem("accessToken", accessToken);

            // Retry the original request with the new token
            originalRequest.headers.Authorization = `JWT ${accessToken}`;
            return axios(originalRequest);
          })
          .catch((error) => {
            // Handle refresh token error or redirect to login
            console.error("Error getting refresh token", error);
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
          });
      }
      return Promise.reject(error);
    },
  );

  switch (method) {
    case "GET":
      return api.get(url).then((response) => response.data as T);
    case "POST":
      return api.post(url).then((response) => response.data as T);
    default:
      throw new Error("Method not supported");
  }
}

// Currently only used for logout (/be-api/partner/logout/)
export const postWithAuth = (url: string) => fetchWithAuth(url, "POST");
