import { Link, Tooltip, Typography } from "@mui/joy";
import { useState } from "react";

export default function IdDisplay({ id }: { id: string }) {
  const [justCopied, setJustCopied] = useState(false);

  return (
    <Tooltip
      arrow
      placement="right"
      size="sm"
      title={justCopied ? "Copied!" : "Click to copy"}
    >
      <Link
        underline="none"
        variant="plain"
        width="fit-content"
        onClick={() => {
          navigator.clipboard.writeText(id);
          setJustCopied(true);
          setTimeout(() => {
            setJustCopied(false);
          }, 1000);
        }}
      >
        <Typography level="body-sm" sx={{ m: 0.5 }}>
          {id}
        </Typography>
      </Link>
    </Tooltip>
  );
}
