import { DonutLarge, Handshake, ReplayCircleFilled } from "@mui/icons-material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { Button, Modal, ModalClose, ModalDialog } from "@mui/joy";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import GlobalStyles from "@mui/joy/GlobalStyles";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { Link, useNavigate } from "@tanstack/react-router";
import React, { useEffect } from "react";
import BoomerangIcon from "../assets/boomerang.svg?react";
import { useLogout } from "../hooks/auth";
import LogInForm from "../pages/login/LoginForm";
import { useUserProfileStore } from "../stores/userStore";

export default function Sidebar() {
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const { userProfile, fetchProfile: fetchUserProfile } = useUserProfileStore();
  const logout = useLogout();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userProfile) {
      fetchUserProfile();
    }
  });

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {
          xs: "fixed",
          md: "sticky",
        },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
      />
      <Link
        to="/"
        style={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          textDecoration: "none",
        }}
      >
        <BoomerangIcon />
        <Typography level="title-lg">Boom Admin</Typography>
      </Link>
      <Input
        size="sm"
        startDecorator={<SearchRoundedIcon />}
        placeholder="Search (⌘K)"
        onClick={() => navigate({ to: "/omnisearch" })}
      />
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            "--List-nestedInsetStart": "30px",
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton
              selected={location.pathname === "/"}
              component={Link}
              to="/"
            >
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Dashboard</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={location.pathname === "/partners"}
              // role="menuitem"
              component={Link}
              to="/partners"
            >
              <Handshake />
              <ListItemContent>
                <Typography level="title-sm">Partner metrics</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={location.pathname === "/metrics"}
              component={Link}
              to="/metrics"
            >
              <DonutLarge />
              <ListItemContent>
                <Typography level="title-sm">Business metrics</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={location.pathname === "/returns"}
              component={Link}
              to="/returns"
            >
              <ReplayCircleFilled />
              <ListItemContent>
                <Typography level="title-sm">Returns</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={location.pathname === "/attributes"}
              component={Link}
              to="/attributes"
            >
              <ReplayCircleFilled />
              <ListItemContent>
                <Typography level="title-sm">Attributes Tool</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>

        <List
          size="sm"
          sx={{
            mt: "auto",
            flexGrow: 0,
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
            "--List-gap": "8px",
            mb: 2,
          }}
        >
          <ListItem>
            <ListItemButton>
              <SettingsRoundedIcon />
              Settings
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        {userProfile ? (
          <>
            <Avatar variant="solid" size="sm" color="primary">
              {userProfile.display_name
                .split(" ")
                .map((word) => word.charAt(0))
                .join("")}
            </Avatar>
            <Box sx={{ minWidth: 0, flex: 1 }}>
              <Typography level="title-sm">
                {userProfile.display_name}
              </Typography>
              <Typography
                level="body-xs"
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {userProfile.email}
              </Typography>
            </Box>
            <IconButton
              size="sm"
              variant="plain"
              color="neutral"
              onClick={logout}
            >
              <LogoutRoundedIcon />
            </IconButton>
          </>
        ) : (
          <Button
            fullWidth
            size="sm"
            variant="outlined"
            startDecorator={<Avatar size="sm" />}
            onClick={() => setShowLoginModal(true)}
          >
            Sign in
          </Button>
        )}
        <Modal open={showLoginModal} onClose={() => setShowLoginModal(false)}>
          <ModalDialog>
            <ModalClose />
            <Typography level="h3">Sign in</Typography>
            <LogInForm setShowLoginModal={setShowLoginModal} />
          </ModalDialog>
        </Modal>
      </Box>
    </Sheet>
  );
}
