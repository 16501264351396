import { Lock } from "@mui/icons-material";
import { Avatar, Container, Sheet, Typography } from "@mui/joy";
import LogInForm from "./LoginForm";

// TODO: Stop flip-flopping between login and sign-in
export default function SignIn() {
  return (
    <Container component="main" maxWidth="sm">
      <Sheet
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <Avatar>
          <Lock />
        </Avatar>
        <Typography component="h1">Sign in</Typography>
        <LogInForm />
      </Sheet>
    </Container>
  );
}
