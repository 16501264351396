import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { useUserProfileStore } from "../stores/userStore";
import { postData, postWithAuth } from "../utils/fetching";

export type LoginRequest = {
  email: string;
  password: string;
};

type TokenResponse = {
  access: string;
  refresh: string;
};

export function useLogin(
  setShowLoginModal: ((value: boolean) => void) | undefined,
) {
  const [isLoggingIn, setLoggingIn] = useState(false);
  const navigate = useNavigate();

  return {
    isLoggingIn,
    login: (request: LoginRequest) => {
      setLoggingIn(true);
      postData<TokenResponse>("/be-api/partner/token/", request, {
        xsrfHeaderName: "X-CSRFTOKEN",
        xsrfCookieName: "csrftoken",
        withCredentials: true,
      })
        .then((data) => {
          localStorage.setItem("accessToken", data.access);
          localStorage.setItem("refreshToken", data.refresh);
          navigate({ to: "/" });
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          setLoggingIn(false);
          setShowLoginModal && setShowLoginModal(false);
        });
    },
  };
}

export function useLogout() {
  const { clearProfile } = useUserProfileStore();
  const navigate = useNavigate();
  return () => {
    postWithAuth("/be-api/partner/logout/")
      .then(() => {
        clearProfile();
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate({ to: "/" });
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
