import { createFileRoute } from "@tanstack/react-router";
import { Outlet } from "@tanstack/react-router";
import SignIn from "../pages/login";

export const Route = createFileRoute("/_protected")({
  component: () => {
    if (isAuthenticated()) {
      return <Outlet />;
    }
    return <SignIn />;
  },
});

function isAuthenticated() {
  return !!localStorage.getItem("accessToken");
}
