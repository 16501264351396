import { create } from "zustand";
import { fetchWithAuth } from "../utils/fetching";

export interface UserProfile {
  id: string;
  email: string;
  full_name: string;
  display_name: string;
  job_title: string;
  phone_number: string;
  partners: Array<string>;
}

interface UserProfileState {
  userProfile: UserProfile | null;
  fetchProfile: () => void;
  clearProfile: () => void;
}

export const useUserProfileStore = create<UserProfileState>()((set) => ({
  userProfile: null,
  fetchProfile: async () => {
    const userProfile = await fetchWithAuth<UserProfile>("/be-api/partner/me/");
    set({ userProfile });
  },
  clearProfile: () => {
    set(() => ({ userProfile: null }));
  },
}));
